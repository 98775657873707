import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import { getSupportChat } from "../redux/actions/adminActions";
import { socket } from "../Socket";
import Logo from "../Assets/Images/Profile-icon.png"


export default function SupportChat() {
    
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const [chats, setChats] = useState([]);
  const [userdetails, setUserdetails] = useState([]);
  console.log(userdetails);
  const [flag, setflag] = useState(true);
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location);
  const senderid = sessionStorage.getItem("adminId")

  useEffect(() => {
    dispatch(getSupportChat({ id: location?.state?.id }))
      .then((data) => {
        console.log(data);
        if (data?.payload?.status === 200) {
          setChats(data?.payload?.data?.chats);
          setUserdetails(data?.payload?.data?.userdetails);
        }
      })
      .catch((error) => {
        console.error("Error fetching supports:", error);
      });
  }, [flag]);


  socket.on("connect", () => {
    console.log("connetected");
  });
  const handleSend = () => {
    socket.emit("send_support", {
      message: message,
      support_id: location?.state?.id,
      sender_id: senderid,
      sender_type: 0,
    });
    setflag(!flag);
    setMessage("");
  };

  socket.on(chats[0]?.support_id, (res) => {
    console.log(res);
  });


  return (
 
    <Layout>
    <div className="right-top">
      <div className="heading-top-inner">
        <div>
          <h2>Support Chat</h2>
        </div>
        <div></div>
      </div>
      <hr />
    </div>

    <Container fluid>
      <div className="product-cmn-tab">
        <Row>
          <Col lg={12}>
            <div className="product-tab-left">
              <Link to="/support">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="42"
                  viewBox="0 0 40 42"
                  fill="none"
                >
                  <path
                    d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                    fill="#40413A"
                  />
                </svg>{" "}
                Back to previous page
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </Container>

    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={8}>
          <div className="support-chat-box-main">
            <div className="support-inner">
              <h2>Chat</h2>

              <div fluid ref={chatContainerRef} className="support-msg-box">
                {chats?.map((details) => {
                  console.log(details?.docModel);
                  return (
                    <>
                      {details?.docModel == "User" ? (
                      <div >
                        <div className="user-box">
                          <div className="user-top-hdng">
                            <img
                              src={`${BASE_URL}${userdetails?.userimage}`}
                            />
                            <h3>{details?.sender_id?.full_name}</h3>
                            <h4>
                              {new Date(
                                details?.createdAt
                              ).toLocaleTimeString()}
                            </h4>
                          </div>
                          <div className="user-msg-box">
                            <p>{details?.message}</p>
                          </div>
                        </div>
                      </div>
                      ) : (
                        <div className="right-side">
                          <div className="user-box ">
                            <div className="user-top-hdng">
                              <h3></h3>
                              <h4>
                                {new Date(
                                  details?.createdAt
                                ).toLocaleTimeString()}
                              </h4>
                              <img
                                src={
                                  details?.profile_image
                                    ? `${BASE_URL}/${details?.profile_image}`
                                    : Logo
                                }
                              />
                            </div>
                            <div className="user-msg-box">
                              <p className="m-0">{details?.message}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
               

              </div>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSend();
              }}
            >
              <div class="send-box">
                <div class="mb-3 input-group">
                  <input
                    placeholder="Type your message here..."
                    aria-label="send"
                    aria-describedby="basic-addon2"
                    name="message"
                    type="text"
                    class="send-feild form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <button
                    class="input-group-text"
                    id="basic-addon2"
                    type="submit"
                  >
                    <img
                      src={require("../Assets/Images/toggle.svg").default}
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
  );
}
