import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../services/adminAPI";



const login = createAsyncThunk(
    "login", 
    async(details) => {
        const { data } = await AdminAPI.post('/auth/login',  details);
        console.log(data,"datadata");
       
        sessionStorage.setItem("token", data?.data?.token);
        sessionStorage.setItem("adminId", data?.data?.Admin?._id);
        console.log(data?.data?.token);

        return data;
    }
);

export { login };