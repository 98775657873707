import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal, Pagination } from "react-bootstrap";
import { DeleteNotification, NotiListing } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function Notification() {
  const [page, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [Status, setstatus] = useState("");
  const [showdel, setshowdel] = useState(false);
  const [id, setId] = useState("");

  const [notificationData, setNotificationData] = useState([]);
  const [pagi, setpagi] = useState("");
  console.log(pagi);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(NotiListing({ limit, search, page }))
      .then((data) => {
        console.log(data);
        if (data?.payload?.status === 200) {
          setNotificationData(data?.payload?.data?.notifications);
          setpagi(data?.payload?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching supports:", error);
      });
  }, [limit, search, page, flag]);

  const handleDeleteNotification = async () => {
    const data = await dispatch(DeleteNotification({ id: id }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setflag(!flag);
      setshowdel(false);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Notifications</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={10} xl={10} lg={9} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col  xxl={2} xl={2}  lg={3} className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/AddNotification">Create New</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Notification" className="active-tab">
                  ALL
                </Link>
              </li>
              {/* <li>
                <Link to="#">Super Admin 2</Link>
              </li>
              <li>
                <Link to="#">Accounts 1</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                {pagi?.currentPage
                  ? 1 + (pagi.currentPage - 1) * limit
                  : 0} -{" "}
                {pagi?.currentPage
                  ? Math.min(pagi.currentPage * limit, pagi.totalCount)
                  : 0}{" "}
                of {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Description</th>
                {/* <th>Send To</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {notificationData?.map((res, index) => {
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{res?.title || "N/A"}</td>
                    <td>{res?.desc || "N/A"}</td>
                    {/* <td>{res?.send_to  || "N/A"}</td> */}
                    <td>
                      <Button
                        onClick={() => {
                          setshowdel(true);
                          setId(res?._id);
                        }}
                        className="account-btn common-colr-btn"
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpages}
          // search={search}
        />
      </div>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text" style={{ textAlign: "center" }}>
            Are you sure you want to remove this notification ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteNotification}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
