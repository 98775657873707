import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import {
  chatStatusChange,
  dowanloadSupport,
  getSuports,
} from "../redux/actions/adminActions";
import { Pagination } from "react-bootstrap";

export default function Support() {
  const [page, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);

  const [Supportdetails, setSupportdetails] = useState([]);
  const [pagi, setpagi] = useState("");
  const [status, setStatus] = useState("");


  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSuports({ limit, search, page, status}))
      .then((data) => {
        console.log(data);
        if (data?.payload?.status === 200) {
          setSupportdetails(data?.payload?.data?.supports);
          setpagi(data?.payload?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching supports:", error);
      });
  }, [limit,search, page,status,flag]);

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(dowanloadSupport());
      console.log(response);
      const allData = response?.payload?.data?.supports;
      const ws = XLSX.utils.json_to_sheet(allData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  const handlerAction = async (id, type) => {
    const data = await dispatch(chatStatusChange({ id: id, status: type }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.message);
      setflag(!flag);
    }
  };

  const handleuprofile = (user) => {
    navigate("/supportChat", {
      state: {
        id: user,
      },
    });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={4} lg={5} md={5}>
                <Form.Select className="m-0" aria-label="Default select example" onChange={(e) => setStatus(e.target.value)}>
                  <option>Status</option>
                  <option >All</option>
                  <option value="1">Open</option>
                  <option value="0">Closed</option>
                </Form.Select>
              </Col>
           
              <Col xxl={6} xl={5} lg={2} md={2}  className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Support" className="active-tab">
                  ALL
                </Link>
              </li>
              {/* <li>
                <Link to="#">Open 18</Link>
              </li>
              <li>
                <Link to="#">Closed 160</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {pagi?.page ? 1 + (pagi?.page - 1) * limit : 0} -{" "}
                {pagi?.page
                  ? Math.min(pagi?.page * limit, pagi?.totalCount)
                  : 0}{" "}
                of {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
              <th>S.No</th>
                <th>Date</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Message</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {Supportdetails?.map((items, index) => {
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      {items?.user_id?.createdAt
                        ? new Date(items?.user_id?.createdAt)
                            .toISOString()
                            .slice(0, 10)
                            .replace(/-/g, "/")
                        : "N/A"}
                    </td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleuprofile(items?._id)}
                      >
                        <b>
                          {items?.user_id?.full_name
                            ? items?.user_id?.full_name
                            : "NA"}
                        </b>
                      </p>
                    </td>
                    <td>{items?.user_id?.email || "N/A"}</td>
                    {/* <td>+91 9876 543 210</td> */}
                    <td>
                    {items?.message ? items?.message : "N/A"}
                    </td>
                    <td>{items?.status === 0 ? "Active" : "Close"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select aria-label="Default select example"  onChange={(e) => {
                            handlerAction(items?._id, e.target.value);
                          }}
                          value={items?.status}>
                         <option value="0">Active</option>
                          <option value="1">Close</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpages}
          // search={search}
        />
      </div>
    </Layout>
  );
}
